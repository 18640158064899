<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Покупка</div>
                <div class="page__desc">Оформление поставки</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="supplyPlan !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="store_id">Магазин</label>
                <div v-if="stores" class="control-group">
                    <select
                            v-model="supplyPlan.store_id"
                            id="store_id"
                            class="form-control"
                            disabled>
                        <option
                                v-for="store in stores"
                                v-bind:key="store.id"
                                :value="store.id">{{ store.address }}</option>
                    </select>
                </div>
                <br>

                <label for="product_id">Продукт</label>
                <div v-if="products" class="control-group">
                    <select
                            v-model="supplyPlan.product_id"
                            id="product_id"
                            class="form-control"
                            disabled>

                        <template
                                v-for="cc in products"
                                :label="cc['title']">

                            <optgroup
                                    v-bind:key="cc['id']"
                                    :label="cc['title']"></optgroup>

                            <optgroup
                                    v-for="c in cc['categories']"
                                    v-bind:key="c['id']"
                                    :label="'↳' + c['title']">

                                <option
                                        v-for="p in c['products']"
                                        v-bind:key="p['id']"
                                        :value="p['id']">{{ p['title'] }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label for="quantity_purchased">Закуплено</label>
                <div class="control-group">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="input-group">
                                <input
                                    v-model="supplyPlan.quantity_purchased"
                                    :placeholder="supplyPlan.quantity_ordered"
                                    id="quantity_purchased"
                                    type="number"
                                    class="form-control"
                                    required>
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-light" disabled> / </button>
                        <div class="col-sm-4">
                            <div class="input-group">
                                <input
                                    v-model="supplyPlan.quantity_ordered"
                                    id="quantity_ordered"
                                    type="number"
                                    class="form-control"
                                    placeholder="20"
                                    disabled>
                                <div v-if="product != null" class="input-group-append">
                                    <span class="input-group-text">{{ product.packaging_type }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <label for="expected_at">Дата ожидания</label>
                <div class="control-group">
                    <input
                            v-model="expected_at"
                            id="expected_at"
                            type="datetime-local"
                            class="form-control"
                            disabled>
                </div>
                <br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'StoreSuppliesPlanPurchase',
        computed: {
            supplyPlan() {
                return this.$store.state.suppliesPlan.supplyPlan
            },
            expected_at: {
                get() {
                    return this.$moment(this.$store.state.suppliesPlan.supplyPlan.expected_at * 1000).format('YYYY-MM-DDTHH:mm')
                },
                set(newVal) {
                    this.$store.state.suppliesPlan.supplyPlan.expected_at = this.$moment(newVal).unix()
                }
            },
            products() {
                return this.$store.state.promotions.promotionsProducts
            },
            stores() {
                return this.$store.state.stores.stores
            },
            product() {
                if (this.products != null) {
                    for (let i = 0; i < this.products.length; i++) {
                        for (let j = 0; j < this.products[i].categories.length; j++) {
                            for (let k = 0; k < this.products[i]['categories'][j]['products'].length; k++) {
                                if (this.supplyPlan.product_id === this.products[i]['categories'][j]['products'][k]['id']) {
                                    return this.products[i]['categories'][j]['products'][k];
                                }
                            }
                        }
                    }
                }
                return null;
            }
        },
        methods: {
            ...mapActions([
                'storeStores',
                'storeSuppliesPlanGet',
                'storeSuppliesPlanPurchase',
                'catalogPromotionsProducts',
            ]),
            async getStores() {
                await this.storeStores()
            },
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async getSupplyPlan() {
                await this.storeSuppliesPlanGet({
                    id: this.$route.params.id,
                })
            },
            async update() {
                await this.storeSuppliesPlanPurchase({
                    id: this.supplyPlan.id,
                    quantity_purchased: this.supplyPlan.quantity_purchased
                })
            }
        },
        created() {
            this.getSupplyPlan();
            this.getStores();
            this.getProducts();
        }
    }
</script>